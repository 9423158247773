:root{
  --black-color: #0b0c17;
  --pink-color: #d56bf2;
  --purple-color: #7f6df2;
  --blue-color: #6db4f2;
  --aqua-color: #6bf2e5;
  --white-color: #f2f3f4;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--blue-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Gopher";
  src: local("Gopher"),
  url("./fonts/Gopher-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Gopher";
  src: local("Gopher"),
  url("./fonts/Gopher-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Gopher";
  src: local("Gopher"),
  url("./fonts/Gopher-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Space Mono";
  src: local("Space Mono"),
  url("./fonts/SpaceMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Space Mono";
  src: local("Space Mono"),
  url("./fonts/SpaceMono-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Space Mono";
  src: local("Space Mono"),
  url("./fonts/SpaceMono-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Anton";
  src: local("Anton");
  src: url("./fonts/Anton.ttf") format("truetype");
}