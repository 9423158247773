html{
    scroll-behavior: smooth;
}

.home_page {
    background-color: var(--white-color);
    
    background-size: 100%;
    color: var(--black-color);
}

.slide-box{
    color: black;
}

.header-box{
    padding-top: 2vh;
    padding-left: 10vw;
    background-color: var(--white-color);
}

.logo{
    width: 50vw;
    z-index: 200;
    padding-bottom: 2vh;
    cursor: pointer;
}

.burger2{
    position: absolute;
    top:0;
    right: 0;
    padding-top: 4vw;
    padding-right: 10vw;
    height: 4.234vw;
    cursor: pointer;
}


.split-box{
    padding-left: 10vw;
    padding-bottom: 3vh;
}

.split-info{
    background-color: var(--black-color);
    height: 3px;
    width: 100vw;
}

.split-info-box{
    padding-left: 10vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.sides-gradient-overlay {
    position: fixed;
    top: 0;
    width: 9vw;
    height: 100vh;
    pointer-events: none; /* Allow interaction with the content beneath */
    z-index: 1;
}

.gradient-left {
    left: 0;
    background: linear-gradient(to right, var(--black-color), transparent);
}

.gradient-right {
    right: 0;
    background: linear-gradient(to left, var(--black-color), transparent);
}

.gradient-top {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 10vh;
    pointer-events: none; /* Allow interaction with the content beneath */
    z-index: 2;
    top: 0;
    background: linear-gradient(to bottom, var(--black-color), transparent);
}

.return_to_top{
    text-align: center;
    align-items: center;
    padding-top: 3vh;
    font-family: 'Courier New', Courier, monospace;
    cursor: pointer;
}

.return_to_top:hover{
    font-weight: bold;
}