.all_photos_pad{
    padding-top: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;
    background-color: var(--white-color);
}

.photos_container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: var(--white-color);
}

.a_photo{
    padding: 1vw;
    width: 24.4vw;
    height: 24.4vw;
}

.photo_fit{
    object-fit: cover;
    cursor: pointer;
}

.photo_fit:hover{
    border-color: var(--white-color);
    border-style: solid;
}

.photo-return_to_top{
    text-align: center;
    align-items: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
    font-family: 'Courier New', Courier, monospace;
    cursor: pointer;
}

.photo-return_to_top:hover{
    font-weight: bold;
}