.burger {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 4vw;
    padding-right: 10vw;
    height: 4.234vw;
    cursor: pointer;
    z-index: 200;
}

.menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    background-color: #fff;
    transition: max-height 0.5s ease-in-out;
    z-index: 150;
}

.menu-open {
    max-height: 300px; /* Adjust this as needed */
}

.menu ul {
    list-style: none;
    padding: 20px;
    margin: 0;
}

.menu li {
    margin: 10px 0;
}

.menu a {
    text-decoration: none;
    color: var(--black-color);
}

.menu_items{
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 2vh;
    padding-right: 15vw;
    cursor: pointer;
    color: var(--black-color);
    text-align: right;
}
.menu_items:hover{
    color: var(--blue-color);
}
.menu_items:active{
    color: var(--black-color);
}