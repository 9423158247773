.body-box{
    color: black;
}

.info-row {
    display: flex;
  }
  
.info-column {
    flex: 50%;
    align-content: center;
}

.left-title {
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 2vw;
}


.left-info{
    text-align: center;
    word-wrap: break-word;
    width: 95%;
    font-family: Space Mono;
    font-size: 1.25vw;
    padding: 3%;
}

.button-slider-container {
    position: relative;
    overflow: hidden;
}

.button-slider-image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    cursor: pointer;
}

.button-slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-family: 'Courier New', Courier, monospace;
    font-size: 2vw;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 1;
}

.button-slider-button-left {
    left: 10px;
}

.button-slider-button-right {
    right: 10px;
}

.button-slider-button:hover {
    background: rgba(0, 0, 0, 0.8);
}

.button-slider-item-name{
    position: absolute;
    text-align: center;
    bottom: 0%;
    width: 100%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-family: 'Courier New', Courier, monospace;
    font-size: 2vw;
    padding: 0.5rem;
    z-index: 1;
}