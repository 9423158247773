.slider-container {
    position: relative;
    overflow: hidden;
  }
  
  .slider-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease-in-out;
    opacity: 0;
    cursor: pointer;
    filter: brightness(1);
    object-fit: cover;
  }
  
  .slider-image.active {
    opacity: 1;
  }
  