.proj_name{
    font-family: Gopher;
    color: var(--black-color);
    font-size: 3vw;
    font-style: italic;
    padding-bottom: 1%;
}

.langmods{
    font-family: Space Mono;
    font-size: 1.25vw;
    padding-bottom: 0.5%;
}
.langmodsB{
    font-family: Space Mono;
    font-size: 1.25vw;
    padding-bottom: 0.5%;
    color: var(--black-color);
    display: inline;
}

.proj_info{
    font-family: Space Mono;
    font-size: 1.25vw;
    padding-bottom: 0.5%;
}
.proj_infoB{
    font-family: Space Mono;
    font-size: 1.25vw;
    padding-bottom: 0.5%;
    display: inline;
    color: var(--black-color);
}

.proj_link{
    font-family: Space Mono;
    font-size: 1.25vw;
    color: var(--white-color);
}
.proj_link:hover{
    color:var(--aqua-color);
}

.all_proj{
    padding-bottom: 3%;
}

.prog_pad{
    padding-top: 15vh;
    padding-left: 5vw;
    padding-right: 5vw;
}