.all_info{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
}

.info_box_pad{
    padding-top: 10vh;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 10vh;
}

.info_box{
    background-color: var(--white-color);
    height: 80vh;
    color: var(--black-color);
    border-color: var(--black-color);
    border-width: 1px;
    border-style: solid;
}

.info_box_back{
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    cursor: pointer;
    font-size: 2vh;
    color: var(--white-color);
}
.info_box_back:hover{
    text-decoration: underline;
}

.info_info{
    word-wrap: break-word;
    font-family: 'Courier New', Courier, monospace;
    color: var(--black-color);
    padding: 5%;
    font-size: 1.7vw;
}